import { useState } from "react";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import {
  Container,
  Box,
  Stack,
  Button,
  InputBase,
  Typography,
  Hidden,
  Chip as MuiChip,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import useLocales from "hooks/useLocales";
import useSearch from "hooks/useSearch";

const Chip = styled(MuiChip)(() => ({
  padding: "24px 5px",
  fontSize: 20,
  borderRadius: 30,
  background: "linear-gradient(30deg, #9F1FED, #645FF2, #38BDD1)",
  backgroundClip: "text",
  textFillColor: "transparent",
  border: "1px solid #666",
}));

const domains = ["yoddaone", "ux"];

export default function Hero() {
  const { account } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useSearch();
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { translate } = useLocales();
  return (
    <Stack
      sx={{
        pt: { xs: 5, md: 10 },
        // background: "url(/images/star_bg.png), url(/images/planet_bg.png)",
        // backgroundSize: "100%, 100%",
        // backgroundRepeat: "no-repeat, no-repeat",
        // backgroundPosition: "0px 0px, center bottom"
        // // height: "90vh"
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Container maxWidth="lg">
        <Stack alignItems="center">
          {/* <Typography variant="h1">
            {translate("Homepage.hero_title1")}
          </Typography> */}
          <Box component="img" src="/images/yoddaone.png" sx={{ width: 500 }} />
          {/* <Box
            component="img"
            src="/images/yoddaverse.jpg"
            sx={{ width: 500 }}
          /> */}
          <Typography
            variant="h3"
            align="center"
            sx={{
              background: "linear-gradient(30deg, #9F1FED, #645FF2, #38BDD1)",
              paddingBottom: "16px",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            {translate("Homepage.hero_title2")}
          </Typography>
          <Stack direction="row" spacing={2} mb={3}>
            {domains.map((item, index) => (
              <Chip
                key={index}
                label={`.${item}`}
                variant="outlined"
                onClick={() => {}}
              />
            ))}
          </Stack>

          <Paper
            sx={{
              height: 78,
              borderRadius: 1.5,
              pl: 3,
              mt: 5,
              width: { xs: 1, md: 0.7 },
              boxShadow: (theme) =>
                theme.palette.mode === "light"
                  ? "0px 0px 10px 2px #cccccc"
                  : "0px 0px 10px 2px #222222",
            }}
          >
            <Stack direction="row" sx={{ height: 1 }}>
              <InputBase
                sx={{ width: 1 }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                startIcon={<SearchIcon sx={{ width: 30, height: 30 }} />}
                sx={{ px: 5, fontSize: 20 }}
                component={RouterLink}
                to="/app/buy"
              >
                {translate("Homepage.btn_search")}
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Container>

      {/* <Stack
        sx={{
          background: "linear-gradient(30deg, #9F1FED, #645FF2, #38BDD1)",
          width: 1,
          p: 5,
          mt: 10,
        }}
      >
        <Container maxWidth="lg">
          <Hidden lgDown>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={20}
              sx={{ color: "white" }}
            >
              <Stack alignItems="center" spacing={1}>
                <Typography variant="h2">1M+</Typography>
                <Typography>{translate("Homepage.stat1")}</Typography>
              </Stack>
              <Stack alignItems="center" spacing={1}>
                <Typography variant="h2">100+</Typography>
                <Typography>{translate("Homepage.stat2")}</Typography>
              </Stack>
              <Stack alignItems="center" spacing={1}>
                <Typography variant="h2">50+</Typography>
                <Typography>{translate("Homepage.stat3")}</Typography>
              </Stack>
            </Stack>
          </Hidden>

          <Hidden lgUp>
            <Stack justifyContent="center" spacing={3} sx={{ color: "white" }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h2">1M+</Typography>
                <Typography>{translate("Homepage.stat1")}</Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h2">100+</Typography>
                <Typography>{translate("Homepage.stat2")}</Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h2">50+</Typography>
                <Typography>{translate("Homepage.stat3")}</Typography>
              </Stack>
            </Stack>
          </Hidden>
        </Container>
      </Stack> */}

      {/* <Container maxWidth="md" sx={{ mt: 8 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          spacing={5}
        >
          <Stack flex={1} spacing={5}>
            <Typography variant="h3">
              {translate("Homepage.intro1_title")}
            </Typography>
            <Stack spacing={2}>
              <Typography variant="h5">
                {translate("Homepage.intro1_subtitle")}
              </Typography>
              <Typography component="ul" sx={{ pl: 3 }}>
                <Typography component="li">
                  {translate("Homepage.intro1_text1")}
                </Typography>
                <Typography component="li">
                  {translate("Homepage.intro1_text2")}
                </Typography>
                <Typography component="li">
                  {translate("Homepage.intro1_text3")}
                </Typography>
                <Typography component="li">
                  {translate("Homepage.intro1_text4")}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Stack flex={1}>
            <Box component="img" src="/images/decentralized-identity.svg" />
          </Stack>
        </Stack>
      </Container> */}

      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle variant="h3" onClose={handleClose}>
          Terms of Service
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h4">Manifesto</Typography>
          <Typography gutterBottom>
            Yoddaone is inspired by the ens dao. We are diverse, with members
            from many different cultures. We strive to create an environment,
            that incentivizes members to use their talents, and essentially
            doing what they love, while achieving financial stability.
          </Typography>
          <Typography variant="h4">Member rules</Typography>

          <Typography component="ol" sx={{ ml: 2 }}>
            <Typography component="li" gutterBottom>
              Every member has the right of freedom of expression, as long as it
              doesn’t harm another member.
            </Typography>
            <Typography component="li" gutterBottom>
              Anyone can join, create a proposal and vote. We encourage talented
              people with good intentions, to join us.
            </Typography>
            <Typography component="li" gutterBottom>
              To join Yoddaone; register a name under '.yoddaone'
            </Typography>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="warning"
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label="I agree on terms of use"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!checked} onClick={handleClose}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
